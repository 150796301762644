<template>
  <lab-task> <lab-density-of-water :salt-concentration="saltConcentrationValue" /></lab-task>
</template>

<script>
import Vue from 'vue';
import LabTask from './labs/LabTask';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import LabDensityOfWater from '@/tasks/components/simulations/LabDensityOfWater';

export default Vue.extend({
  name: 'DensityOfWater',
  components: {LabDensityOfWater, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    saltConcentration() {
      return this.taskState.getValueBySymbol('saltValue').content;
    },
    saltConcentrationValue() {
      return this.saltConcentration.toFloat();
    },
  },
});
</script>
