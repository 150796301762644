<template>
  <div v-if="loaded" style="width: 100%; height: 100%">
    <sim-wrapper style="width: 100%; height: 100%">
      <stemble-density-of-water :salt-value="saltConcentration" v-bind="$attrs" v-on="$listeners" />
    </sim-wrapper>
  </div>
  <stemble-loader v-else />
</template>

<script>
import Vue from 'vue';
import {loadScriptOnce, loadStylesOnce} from '@/loading/utils/dynamic-loading';
import StembleLoader from '@/loading/components/StembleLoader';
import SimWrapper from '@/tasks/components/simulations/SimWrapper';

export default Vue.extend({
  name: 'LabDensityOfWater',
  components: {StembleLoader, SimWrapper},
  inheritAttrs: false,
  props: {
    saltConcentration: {
      type: Number,
      default: 0.0,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    Promise.all([
      loadScriptOnce('/simulations/density-of-water/stemble-density-of-water.js'),
      loadStylesOnce('/simulations/density-of-water/styles.css'),
    ]).then(() => {
      this.loaded = true;
    });
  },
});
</script>
